
import { Fragment } from "react";
import {
  BriefcaseIcon,
  CalendarIcon,
  CurrencyDollarIcon,
  LinkIcon,
  LocationMarkerIcon,
} from "@heroicons/react/solid";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Card({
  btnId,
  title,
  categories,
  city,
  salary,
  postedOn,
}) {
  return (
    <>
      <Link to={`/jobs/${btnId}`}>
        <div className="flex flex-wrap -m-4 ">
          <div className="w-full mb-4 ">
            <div className="border border-gray-200 p-4 rounded-lg bg-gray-100 mb-2 mx-4">
              <div className="lg:flex lg:items-center lg:justify-between">
                <div className="flex-1 min-w-0">
                  <h2 className="text-xl font-bold leading-7 text-gray-900  sm:truncate">
                    {title}
                  </h2>
                  <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <BriefcaseIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-blue-600"
                        aria-hidden="true"
                      />
                      {categories}
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <LocationMarkerIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-blue-600"
                        aria-hidden="true"
                      />
                      {city}
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <CurrencyDollarIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-blue-600"
                        aria-hidden="true"
                      />
                      {salary}
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <CalendarIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-blue-600"
                        aria-hidden="true"
                      />
                      Posted on {postedOn}
                    </div>
                  </div>
                </div>
                <div className="mt-5 flex lg:mt-0 lg:ml-4">
                  <span className="hidden sm:block ml-3">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-blue-600 ease-in duration-100 hover:bg-blue-100 rounded-md shadow-sm text-sm font-medium text-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
                    >
                      <LinkIcon
                        className="-ml-1 mr-2 h-5 w-5 text-blue-600"
                        aria-hidden="true"
                      />
                      View
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}
