import React from 'react'
import { Link } from 'react-router-dom'

function Cta() {
  return (
    <div className="bg-white mx-8 border-y border-gray-200">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">Hiring?</span>
          <span className="block text-blue-600">Sign up today and post your jobs.</span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <a
              rel="noreferrer"
              href="https://www.canadajobleads.ca" target="_blank"
              className="inline-flex items-center justify-center font-[Poppins] text-white bg-blue-600 px-2 py-1 rounded-lg border-2 border-blue-600 hover:text-blue-600 hover:bg-white ease-in duration-150 text-lg"
            >
              Get started
            </a>
          </div>
          <div className="ml-3 inline-flex rounded-md shadow">
            <Link
              to="/about"
              className="inline-flex items-center justify-center font-[Poppins] text-blue-600 bg-white px-4 py-1 rounded-lg border-2 border-blue-600 hover:text-white hover:bg-blue-600 ease-in duration-150 text-lg"
            >
              Learn more
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cta