import React from 'react'
import { Hero, Cities, JobsCard, Resources } from '../components'
import FadeIn from 'react-fade-in';


const Home = () => {
  return (
    <>
      <FadeIn delay={100} transitionDuration ={1000} className='scroll-smooth'>
        <Hero />
        <JobsCard />
        <Cities />
        <Resources />
      </FadeIn>
        
    </>
  )
}

export default Home