import { Route, BrowserRouter as Router, Routes, Link } from "react-router-dom";
import "./App.css";
import { Cta, Footer, Header, JobView, CityJobs, SearchView } from "./components";

import { Home, Jobs, About, Terms,  Dashboard, NotFound } from "./pages";
import { ScrollTop } from "./utils";

function App() {
  return (
    <>
      <Router>

        <ScrollTop>
          <Header />

          <Routes>

            <Route exact path="/employer-dashboard" element={<Dashboard />}></Route>
            
            <Route exact path="/about" element={<About />}></Route>

            <Route exact path="/" element={<Home />}></Route>

            <Route exact path="/jobs" element={<Jobs />}></Route>

            <Route exact path="/jobs/:btnId" element={<JobView  />}></Route>
            
            <Route exact path="/jobs/city/:city" element={<CityJobs  />}></Route>

            <Route exact path="/search/:location/:title" element={<SearchView  />}></Route>
            <Route exact path="/search/*/*" element={<NotFound  />}></Route>

            <Route exact path="/terms-and-conditions" element={<Terms />}></Route>

            <Route exact path="*" element={<NotFound />}></Route>

          </Routes>

          <Cta />
          <Footer />
        </ScrollTop>

      </Router>
    </>
  );
}

export default App;
