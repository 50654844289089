import React from 'react'
import { ChevronLeftIcon } from '@heroicons/react/outline'
import { useNavigate } from 'react-router-dom'

function NotFound() {
  const history = useNavigate();
  return (
    <div className='h-full px-4 mx-4'>
        <button onClick={() => history(-1)}>
          <div className="text-lg font-semibold text-gray-900 flex flex-row items-center">
            <ChevronLeftIcon className="text-gray-900 h-5 w-5" />
            <h1>Back</h1>
          </div>
        </button>
        <h1 className='text-center mx-auto my-64 font-[Poppins] font-bold text-2xl text-gray-900'>404 | Oops! the file you are looking for doesn't exist or has moved.</h1>
    </div>
  )
}

export default NotFound