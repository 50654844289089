import React from 'react';
import able2 from '../assets/able2.png';
import able1 from '../assets/able1.png';
import able3 from '../assets/able3.png';

const Resources = () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="flex flex-wrap w-full mt-20 flex-col items-center text-center">
        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
          Resources
        </h1>
        <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">
          Need help? check out the resources below!
        </p>
      </div>
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
          <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div className="rounded-lg h-64 overflow-hidden">
              <img
                alt="content"
                className="object-cover object-center h-full w-full"
                src={able1}
              />
            </div>
            <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
            People with Disabilities resources, WorkBC
            </h2>
            <p className="text-base leading-relaxed mt-2">
            Build your own career plan and find B.C. resources to choose a career, access education and funding, and get a job.
            </p>
            <a
              rel="noreferrer"
              href="https://www.workbc.ca/resources-for/people-with-disabilities.aspx"
              className="text-blue-500 inline-flex items-center mt-3"
            >
              Learn More
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
          <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div className="rounded-lg h-64 overflow-hidden">
              <img
                alt="content"
                className="object-cover object-center h-full w-full"
                src={able2}
              />
            </div>
            <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
            Opportunities fund for persons with disabilities – Active national projects
            </h2>
            <p className="text-base leading-relaxed mt-2">
            Canadian Association of Community Living and Canadian Autism Spectrum Disorder Association, Restigouche CBDC,...
            </p>
            <a
              rel="noreferrer"
              href="https://www.canada.ca/en/employment-social-development/services/funding/disability-opportunity-national/active-projects.html"
              className="cursor-pointer text-blue-500 inline-flex items-center mt-3"
            >
              Learn More
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
          <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div className="rounded-lg h-64 overflow-hidden">
              <img
                alt="content"
                className="object-cover object-center h-full w-full"
                src={able3}
              />
            </div>
            <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
              Hire persons with disabilities.
            </h2>
            <p className="text-base leading-relaxed mt-2">
            There are 645,000 Canadians with disabilities who have the potential to work in an inclusive labour market and are not currently working.
            </p>
            <a
              rel="noreferrer"
              href="https://www.canada.ca/en/employment-social-development/campaigns/hiring-persons-disabilities.html"
              className="cursor-pointer text-blue-500 inline-flex items-center mt-3"
            >
              Learn More
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resources;