import React, { useState } from "react";
import { Link } from "react-router-dom";
import ablejob from "../assets/ablejob.png";


function Hero() {
  const [searchTerm, setSearchTerm] = useState("term");
  const [searchLocation, setSearchLocation] = useState("loc");

  return (
    <section className="text-gray-600 body-font">
      <div className="container  mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div className="lg:flex-grow lg:pr-12 md:w-2/3 md:pr-8 md:mx-8 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font text-3xl mb-4 font-medium text-gray-900">
            Looking for your next job? Search for jobs and find
            the right fit for you.
          </h1>
          <p className="mb-8 leading-relaxed">
            AbleJobleads is a job listing platform trusted by employers from all
            over Canada to find their next employee. Since our launch, we have
            connected many employers with incredible, able talent through our platform. Job seeker? start your search today, connect with verified employers and find your next professional move
            !{" "}
          </p>
          <div className="flex md:flex-row flex-col w-full justify-between items-center md:items-end">
            <div className="relative flex md:flex-row flex-col mr-4 w-4/5 md:w-full lg:w-full items-center ">
              <input
                required
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Job title, keywords, or company"
                type="text"
                id="hero-field"
                name="hero-field"
                className="w-full bg-gray-100 rounded border bg-opacity-50 border-gray-200 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-blue-600 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
              <input
               required
                onChange={(e) => setSearchLocation(e.target.value)}
                placeholder="City, or Province"
                type="text"
                id="hero-field"
                name="hero-field"
                className="w-full md:ml-4 md:mt-0 mt-4 bg-gray-100 rounded border bg-opacity-50 border-gray-200 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-blue-600 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <Link to={`/search/${searchLocation}/${searchTerm}`}>
            <button className="inline-flex mt-4 md:mt-0 text-white font-[Poppins] bg-blue-600 px-6 py-1 rounded-lg border-2 border-blue-600 hover:text-blue-600 hover:bg-white ease-in duration-150 text-lg">
              Search
            </button>
            </Link>
          </div>
          <p className="text-sm mt-2 font-semibold text-gray-500 mb-8 w-full">
            Job leads tailored for Canadians!
          </p>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="object-cover object-center rounded"
            draggable="false"
            alt="hero"
            src={ablejob}
          />
        </div>
      </div>
    </section>
  );
}

export default Hero;
