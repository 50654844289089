
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { searchQuery, locationQuery, titleQuery, searchQueryMax, locationQueryMax, titleQueryMax } from "../utils/Data";
import Card from "./Card";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { client } from "../client";
import Pagination from "./Pagination";
import { Jobs } from "../pages";


function JobCardRender(job) {
    return (
      <Card
        key={job._id}
        btnId={job._id}
        title={job.title}
        categories={job.categories}
        city={job.city}
        salary={job.salary}
        postedOn={job.postedOn}
      />
    );
  }


function SearchView() {

  const [currentPage, setCurrentPage] = useState(0)
  const [maxOpenings, setMaxOpenings] = useState()

    const history = useNavigate();
    const { location, title } = useParams();
    const [jobsArr, setJobsArr] = useState([]);

    function activePageinc (){
      if(currentPage < ((maxOpenings/10)-1))
        setCurrentPage(currentPage+1)
    }
  
    function activePagedec(){
  
      if(currentPage != 0 ) {
        setCurrentPage(currentPage-1)
      }
      
    }  

  useEffect(() => {
       if(title !="term" && location != "loc"){
        client
        .fetch(searchQuery(title, location,currentPage))
        .then((jobs) => {
          setJobsArr(jobs);
        })
        .catch((err) => console.log(err));

        client
        .fetch(searchQueryMax(title,location))
        .then((res) => {
            setMaxOpenings(res);
        })
        .catch((err) => console.log(err));

    } else if(location == "loc" && title != "term") {
        client
        .fetch(titleQuery(title,currentPage))
        .then((jobs) => {
            setJobsArr(jobs);
        })
        .catch((err) => console.log(err));

        client
        .fetch(titleQueryMax(title))
        .then((res) => {
            setMaxOpenings(res);
        })
        .catch((err) => console.log(err));
        
    } else{ 
        client
        .fetch(locationQuery(location,currentPage))
        .then((jobs) => {
            setJobsArr(jobs);
        })
        .catch((err) => console.log(err));

        client
        .fetch(locationQueryMax(location))
        .then((res) => {
            setMaxOpenings(res);
        })
        .catch((err) => console.log(err));
    }

  }, [currentPage]);




  //Routing
  if(title =="term" && location == "loc"){
      return(
          <Jobs />
      )
  }else if(jobsArr.length < 1){
      return (
        <div className=" flex flex-col items-center justify-center my-56">
          
          <p className="text-gray-800 text-lg mb-2">Invalid Search!</p>
          
            <button onClick={() => history(-1)} className="px-4 py-1 bg-blue-600 rounded text-lg text-white font-semibold flex items-center"><ChevronLeftIcon className="mr-2 text-white h-5 w-5"/>Back </button>
          
        </div>
      )
  }
  return (
    <div className="px-4 mx-4">
      <button onClick={() => history(-1)}>
        <div className="text-lg font-semibold text-gray-900 flex flex-row items-center">
          <ChevronLeftIcon className="text-gray-900 h-5 w-5" />
          <h1>Back</h1>
        </div>
      </button>
        <h1 className="font-bold text-xl text-blue-600 font-[Poppins] mt-8 md:px-16 md:mx-16 px-2 mx-2">Showing Results</h1>
      <div className="md:px-16 md:mx-16 px-2 mx-2 mt-8 mb-16 ">
          {
             jobsArr.map(JobCardRender)
          }
          
          </div>
          <Pagination
            currentPage={currentPage}
            increment={activePageinc}
            decrement={activePagedec}
            maxOpenings={maxOpenings}
          />
    </div>
  );
  
}

export default SearchView